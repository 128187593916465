import globalApi from '../../api'

const interceptRequestSuccess = ({ config, tokens }) => {
  // Do nothing if the isSecure property of the config request is set to false
  if (config.isSecured === false || !tokens) {
    return config
  }

  const { accessToken } = tokens

  // Create the new request config
  const newConfig = { ...config }
  newConfig.headers.Authorization = `Bearer ${accessToken}`
  return newConfig
}

const interceptReponseFailure = async ({ error, refreshToken, logout }) => {
  const { response, config } = error

  const { status } = response

  if (status === 401) {
    try {
      // TODO: add some me verification
      await refreshToken()
      return globalApi.request(config)
    } catch {
      logout()
      return Promise.reject()
    }
  }

  return Promise.reject(error)
}

export default ({ tokens, refreshToken, logout }) => {
  // Intercept the request

  const requestInterceptor = globalApi.interceptors.request.use(
    // Do something before the request is sent
    (config) => interceptRequestSuccess({ config, tokens }),
    // Do something with request error
    (error) => Promise.reject(error),
  )

  // Add a response interceptor
  const responseInterceptor = globalApi.interceptors.response.use(
    // Do something with response data
    (response) => response,
    // Do something with response error
    (error) => interceptReponseFailure({ error, refreshToken, logout }),
  )

  return function removeInterceptors() {
    globalApi.interceptors.request.eject(requestInterceptor)
    globalApi.interceptors.response.eject(responseInterceptor)
  }
}
// clear interceptor
