import globalApi from '../../api'

export const loginApi = ({ username, password }) =>
  globalApi.post('/Token/CreateToken', {
    username,
    password,
  })

export const signUpApi = ({ email, password, confirmPassword }) =>
  globalApi.post('/Account​/register', {
    email,
    password,
    confirmPassword,
  })

export const forgotPasswordApi = ({ email }) =>
  globalApi.post('/Account/ForgotPassword', {
    email,
  })

export const resetPasswordApi = ({ email, password, code }) =>
  globalApi.post('/Account/ResetPassword', {
    email,
    password,
    code,
  })

export const changePasswordApi = ({ oldPassword, newPassword }) =>
  globalApi.post('/Account/ChangePassword', {
    oldPassword,
    newPassword,
    isSecured: true,
  })

export const refreshTokenApi = ({ accessToken, refreshToken }) =>
  globalApi.post('/Token/CreateToken', {
    accessToken,
    refreshToken,
  })

export const meApi = () => globalApi('/Account/Me', { isSecured: true })
