import React, { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { useAlert } from '../../alert/containers/AlertProvider'
import {
  getProfessionsApi,
  getAgeRangesApi,
  getContributorNumberApi,
  getContributorNumberByGenderApi,
  getContributorActiveRateApi,
  getContributorNumberByProfessionApi,
  getContributorNumberByAgeApi,
  getContributorTopWilayasApi,
  getComplaintNumberApi,
  getComplaintNumberByNature,
  getComplaintNumberByTrashType,
  getComplaintNumberByStatus,
  getComplaintTopWilayasApi,
  getTrashTypesApi,
  getNaturesApi,
  getContributorCSVApi,
  getComplaintCSVApi,
} from '../api'
import { getWilayaLabel } from '../../../utils/wilayasAndCommunes'
import { getDictionnaryLabelById } from '../../../utils/dictionnary'
import 'moment/locale/fr'

moment.locale('fr')
export const DashboardContext = createContext()

export const initialContributorFilter = {
  genderIds: [],
  professionIds: [],
  wilayas: [],
  ageRangesIds: [],
  activeIds: [],
}
export const initialComplaintFilter = {
  natureIds: [],
  trashTypeIds: [],
  wilayas: [],
  statuses: [],
}

const initialDate = {
  // 29 days + today in order to have 30 days
  startDate: moment().subtract(29, 'd'),
  endDate: moment(),
}

const statusesDictionnary = [
  { id: 1, label: 'Nouvelle' },
  { id: 2, label: 'Envoyée' },
  { id: 3, label: 'Annulée' },
]

const formatChartData = (chartData) =>
  chartData.reduce(
    (acc, curr) => {
      return {
        labels: [...acc.labels, curr.label],
        data: [...acc.data, curr.count],
      }
    },
    { labels: [], data: [] },
  )
export const DashboardProvider = ({ children }) => {
  const { showAlert } = useAlert()
  const [isLoading, setIsLoading] = useState(false)

  // contributor dictionnary
  const [professions, setProfessions] = useState([])
  const [ageRanges, setAgeRanges] = useState([])

  const [contributorFilter, setContributorFilter] = useState(
    initialContributorFilter,
  )
  const [contributorStat, setContributorStat] = useState({
    numberOfContributors: 0,
    genderStat: { labels: [], data: [] },
    activeContributorRate: { labels: [], data: [] },
    professionStat: { labels: [], data: [] },
    ageStat: { labels: [], data: [] },
    wilayaStat: [],
  })

  const [date, setDate] = useState(initialDate)
  // complaint dictionnary
  const [trashTypes, setTrashTypes] = useState([])
  const [natures, setNatures] = useState([])

  const [complaintFilter, setComplaintFilter] = useState(initialComplaintFilter)

  const [complaintStat, setComplaintStat] = useState({
    numberOfComplaints: 0,
    complaintNumberPerDay: 0,
    trashTypeStat: { labels: [], data: [] },
    natureStat: { labels: [], data: [] },
    statusStat: { labels: [], data: [] },
    wilayaStat: [],
  })

  // get contributor dictionnary
  const getProfessions = async () => {
    try {
      setIsLoading(true)
      const { data } = await getProfessionsApi()
      setProfessions(data)
    } catch {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }
  const getAgeRanges = async () => {
    try {
      setIsLoading(true)
      const { data } = await getAgeRangesApi()
      setAgeRanges(data)
    } catch {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }
  // get contributor stat
  const getContributorStat = async () => {
    try {
      setIsLoading(true)
      const {
        professionIds,
        genderIds,
        wilayas,
        ageRangesIds,
        activeIds,
      } = contributorFilter
      let active = null
      if (activeIds.length === 1 && activeIds[0] === 1) {
        active = true
      } else if (activeIds.length === 1) {
        active = false
      }
      const requestFilter = {
        professions: professionIds,
        genders: genderIds,
        communeIds: [],
        ageRanges: ageRangesIds,
        active,
        wilayaIds: wilayas.map(({ id }) => id),
        ...(date.startDate !== null && {
          dateStart: moment(
            `${date.startDate.format('YYYY-MM-DD')} 00:00:00`,
          ).format(),
        }),
        ...(date.endDate !== null && {
          dateEnd: moment(
            `${date.endDate.format('YYYY-MM-DD')} 23:59:59`,
          ).format(),
        }),
      }

      const requestsData = await Promise.all([
        getContributorNumberApi({
          filter: requestFilter,
        }),
        getContributorNumberByGenderApi({
          filter: requestFilter,
        }),
        getContributorActiveRateApi({
          filter: requestFilter,
        }),
        getContributorNumberByProfessionApi({
          filter: requestFilter,
        }),
        getContributorNumberByAgeApi({
          filter: requestFilter,
        }),
        getContributorTopWilayasApi({
          filter: requestFilter,
        }),
      ])

      const [
        numberOfContributors,
        genderStat,
        activeContributorRate,
        professionStat,
        ageStat,
        wilayaStat,
      ] = requestsData.map(({ data }) => data)

      setContributorStat({
        numberOfContributors,
        genderStat: formatChartData(genderStat),
        activeContributorRate: formatChartData(activeContributorRate),
        professionStat: formatChartData(professionStat),
        ageStat: formatChartData(ageStat),
        wilayaStat: wilayaStat.map(({ label, count: data }) => ({
          label,
          data,
        })),
      })
    } catch (e) {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }

  // get complaint dictionnary
  const getTrashTypes = async () => {
    try {
      setIsLoading(true)
      const { data } = await getTrashTypesApi()
      setTrashTypes(data)
    } catch {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }

  const getNatures = async () => {
    try {
      setIsLoading(true)
      const { data } = await getNaturesApi()
      setNatures(data)
    } catch {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }
  const getComplaintStat = async () => {
    try {
      setIsLoading(true)
      const { natureIds, trashTypeIds, wilayas, statuses } = complaintFilter
      let numberOfDays = date.endDate.diff(date.startDate, 'days')
      numberOfDays = numberOfDays > 0 ? numberOfDays : 1
      const requestFilter = {
        trashTypeIds,
        natureIds,
        communeIds: [],
        statuses,
        wilayaIds: wilayas.map(({ id }) => id),
        ...(date.startDate !== null && {
          startDate: moment(
            `${date.startDate.format('YYYY-MM-DD')} 00:00:00`,
          ).format(),
        }),
        ...(date.endDate !== null && {
          endDate: moment(
            `${date.endDate.format('YYYY-MM-DD')} 23:59:59`,
          ).format(),
        }),
      }

      const requestsData = await Promise.all([
        getComplaintNumberApi({
          filter: requestFilter,
        }),
        getComplaintNumberByNature({
          filter: requestFilter,
        }),
        getComplaintNumberByTrashType({
          filter: requestFilter,
        }),
        getComplaintNumberByStatus({
          filter: requestFilter,
        }),
        getComplaintTopWilayasApi({
          filter: requestFilter,
        }),
      ])
      const [
        numberOfComplaints,
        natureStat,
        trashTypeStat,
        statusStat,
        wilayaStat,
      ] = requestsData.map(({ data }) => data)

      setComplaintStat({
        numberOfComplaints,
        complaintNumberPerDay: numberOfComplaints / numberOfDays,
        trashTypeStat: formatChartData(
          trashTypeStat.map(({ key, count }) => ({
            label: getDictionnaryLabelById({
              id: key,
              dictionnary: trashTypes,
            }),
            count,
          })),
        ),
        natureStat: formatChartData(
          natureStat.map(({ key, count }) => ({
            label: getDictionnaryLabelById({ id: key, dictionnary: natures }),
            count,
          })),
        ),
        statusStat: formatChartData(
          statusStat.map(({ key, count }) => ({
            label: getDictionnaryLabelById({
              id: key,
              dictionnary: statusesDictionnary,
            }),
            count,
          })),
        ),
        wilayaStat: wilayaStat.map(({ key, count: data }) => ({
          label: getWilayaLabel(key),
          data,
        })),
      })
    } catch (e) {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }

  const getContributorCSV = () => {
    const {
      professionIds,
      genderIds,
      wilayas,
      ageRangesIds,
      activeIds,
    } = contributorFilter
    let active = null
    if (activeIds.length === 1 && activeIds[0] === 1) {
      active = true
    } else if (activeIds.length === 1) {
      active = false
    }
    const requestFilter = {
      professions: professionIds,
      genders: genderIds,
      communeIds: [],
      ageRanges: ageRangesIds,
      active,
      wilayaIds: wilayas.map(({ id }) => id),
      ...(date.startDate !== null && {
        dateStart: moment(
          `${date.startDate.format('YYYY-MM-DD')} 00:00:00`,
        ).format(),
      }),
      ...(date.endDate !== null && {
        dateEnd: moment(
          `${date.endDate.format('YYYY-MM-DD')} 23:59:59`,
        ).format(),
      }),
    }
    getContributorCSVApi({ filter: requestFilter })
      .then((res) => new Blob([res.data], { type: 'text/csv' }))
      .then((blob) => {
        saveAs(blob, `statistique_ndif_utilisateur_${Date.now()}.csv`)
      })
  }

  const getComplaintCSV = () => {
    const { natureIds, trashTypeIds, wilayas, statuses } = complaintFilter

    const requestFilter = {
      trashTypeIds,
      natureIds,
      communeIds: [],
      statuses,
      wilayaIds: wilayas.map(({ id }) => id),
      ...(date.startDate !== null && {
        startDate: moment(
          `${date.startDate.format('YYYY-MM-DD')} 00:00:00`,
        ).format(),
      }),
      ...(date.endDate !== null && {
        endDate: moment(
          `${date.endDate.format('YYYY-MM-DD')} 23:59:59`,
        ).format(),
      }),
    }

    getComplaintCSVApi({ filter: requestFilter })
      .then((res) => new Blob([res.data], { type: 'text/csv' }))
      .then((blob) => {
        saveAs(blob, `statistique_ndif_réclamation_${Date.now()}.csv`)
      })
  }
  const init = () => {
    setComplaintFilter({ ...initialComplaintFilter })
    setContributorFilter({ ...initialContributorFilter })
    setDate({ ...initialDate })
  }
  const providerValue = {
    init,
    professions,
    ageRanges,
    genders: [
      { id: 1, label: 'homme' },
      { id: 2, label: 'femme' },
      { id: -1, label: 'non précisé' },
    ],
    actives: [
      { id: 1, label: 'actifs' },
      { id: 2, label: 'innactifs' },
    ],
    contributorStat,
    contributorFilter,
    isLoading,
    setContributorFilter,
    getContributorStat,
    //
    statuses: statusesDictionnary,
    trashTypes,
    natures,
    complaintStat,
    complaintFilter,
    setComplaintFilter,
    getComplaintStat,
    getContributorCSV,
    getComplaintCSV,
    date,
    setDate,
  }

  useEffect(() => {
    if ((professions.length > 0, ageRanges.length > 0)) {
      getContributorStat()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributorFilter, professions, ageRanges, date])

  useEffect(() => {
    if ((natures.length > 0, trashTypes.length > 0)) {
      getComplaintStat()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [natures, trashTypes, complaintFilter, date])

  useEffect(() => {
    getProfessions()
    getAgeRanges()
    getTrashTypes()
    getNatures()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DashboardContext.Provider value={providerValue}>
      {children}
    </DashboardContext.Provider>
  )
}
DashboardProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useDashboard = () => {
  const context = useContext(DashboardContext)
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider')
  }
  return context
}
