/* eslint-disable react/prop-types */
import React from 'react'
import { renderRoutes } from 'react-router-config'
import { Redirect } from 'react-router-dom'
import processRoutes from './helper'
import Firewall from '../modules/security/components/Firewall'
import Layout from '../components/Layout'
import { ComplaintsProvider } from '../modules/complaint/containers/ComplaintsProvider'
import { ContributorsProvider } from '../modules/contributor/containers/ContributorsProvider'
import { DashboardProvider } from '../modules/dashboard/container/DashboardProvider'
import { SupervisorProvider } from '../modules/Supervisor/containers/SupervisorProvider'

const NotFound = () => (
  <div>
    <h3>NotFound</h3>
  </div>
)

const routes = [
  {
    component: Firewall,
    routes: [
      {
        path: '/app',
        component: Layout,
        routes: [
          {
            path: '/',
            component: () => <Redirect to={{ pathname: '/app/dashboard' }} />,
          },
          {
            path: '/dashboard',
            component: ({ route }) => (
              <DashboardProvider>
                {renderRoutes(route.routes)}
              </DashboardProvider>
            ),
            routes: [
              {
                path: '/',
                load: () =>
                  import(/* webpackChunkName: "dashboard" */ './Dashboard.js'),
              },
            ],
          },
          {
            path: '/change-password',
            load: () =>
              import(
                /* webpackChunkName: "change-password" */ './ChangePassword.js'
              ),
          },
          {
            path: '/supervisor',
            roles: ['administrator'],
            component: ({ route }) => (
              <SupervisorProvider>
                {renderRoutes(route.routes)}
              </SupervisorProvider>
            ),
            routes: [
              {
                path: '/',

                load: () =>
                  import(
                    /* webpackChunkName: "supervisor" */ './Supervisor.js'
                  ),
              },
            ],
          },
          {
            path: '/contributors',
            component: ({ route }) => (
              <ContributorsProvider>
                {renderRoutes(route.routes)}
              </ContributorsProvider>
            ),
            routes: [
              {
                path: '/',
                load: () =>
                  import(
                    /* webpackChunkName: "contributors" */ './Contributors.js'
                  ),
              },
            ],
          },
          {
            path: '/complaints',
            component: ({ route }) => (
              <ComplaintsProvider>
                {renderRoutes(route.routes)}
              </ComplaintsProvider>
            ),
            routes: [
              {
                path: '/',
                load: () =>
                  import(
                    /* webpackChunkName: "Complaints" */ './Complaints.js'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: '/login',
        load: () => import(/* webpackChunkName: "Login" */ './Login.js'),
      },
      {
        path: '/forget-password',
        load: () =>
          import(
            /* webpackChunkName: "ForgetPassword" */ './ForgetPassword.js'
          ),
      },
      {
        path: '/reset-password',
        load: () =>
          import(/* webpackChunkName: "ResetPassword" */ './ResetPassword.js'),
      },
    ],
  },
]

export default processRoutes({
  routes,
  notFoundComponent: NotFound,
})
