import globalApi from '../../api'

export const getContributorListApi = ({ filter }) =>
  globalApi.post('/User/FilterPhoneUsers', { ...filter, isSecured: true })

export const getProfessionsApi = () =>
  globalApi.get('/User/Professions', { isSecured: true })

export const getAgeRangesApi = () =>
  globalApi.get('/User/AgeRanges', { isSecured: true })
