import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  loginApi,
  forgotPasswordApi,
  changePasswordApi,
  resetPasswordApi,
  refreshTokenApi,
  meApi,
} from '../api'
import interceptor from '../interceptor'
import { useAlert } from '../../alert/containers/AlertProvider'

export const AuthContext = createContext()

const TOKEN_STORAGE_KEY = 'tokens'

export const AuthProvider = ({ children }) => {
  const { showAlert } = useAlert()
  const history = useHistory()

  const [tokens, setTokens] = useState(null)
  const [isSync, setIsSync] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [me, setMe] = useState(null)

  async function fetchMe() {
    const { data } = await meApi()
    setMe(data)
  }

  async function login({ username, password }) {
    try {
      setIsLoading(true)
      const { data } = await loginApi({
        username,
        password,
      })

      localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(data))

      setTokens(data)
      await fetchMe()
    } catch (error) {
      showAlert({ message: 'Identifiants incorrects' })
    } finally {
      setIsLoading(false)
    }
  }

  const refreshToken = useCallback(async () => {
    const data = await refreshTokenApi(tokens)

    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(data))

    setTokens(data)
  }, [tokens])

  const logout = () => {
    localStorage.clear()
    setTokens(null)
    setMe(null)
  }

  async function forgotPassword({ email }) {
    try {
      setIsLoading(true)
      await forgotPasswordApi({ email })
      showAlert({
        message: 'Reset password email has been sent',
        variant: 'success',
      })
      history.push('/')
    } catch (error) {
      showAlert({
        message: 'une erreur est survenue',
      })
    } finally {
      setIsLoading(false)
    }
  }

  async function resetPassword({ email, password, code }) {
    try {
      setIsLoading(true)
      await resetPasswordApi({ email, password, code })
      showAlert({
        message: 'Mot de passe modifié avec succès',
        variant: 'success',
      })
      history.push('/')
    } catch (error) {
      showAlert({
        message: 'une erreur est survenue',
      })
    } finally {
      setIsLoading(false)
    }
  }

  async function changePassword({ oldPassword, newPassword }) {
    try {
      setIsLoading(true)
      await changePasswordApi({
        oldPassword,
        newPassword,
      })

      showAlert({
        message: 'Mot de passe modifié avec succès',
        variant: 'success',
      })
      history.push('/')
    } catch (error) {
      showAlert({ message: 'Mot de passe incorrect' })
    } finally {
      setIsLoading(false)
    }
  }

  const hasRoles = (requiredRoles) => {
    if (requiredRoles.length === 0) {
      return true
    }
    return Boolean(
      me?.roles.some((userRole) => requiredRoles.includes(userRole)),
    )
  }

  const isAuthenticated = tokens !== null

  const providerValue = {
    isAuthenticated,
    isLoading,
    me,
    login,
    logout,
    hasRoles,
    forgotPassword,
    resetPassword,
    changePassword,
  }

  // axios interceptor : must change on every tokens change
  useEffect(() => {
    const removeInterceptor = interceptor({ tokens, refreshToken, logout })
    return removeInterceptor
  }, [refreshToken, tokens])

  // fetch me in each app opening and token changes
  useEffect(() => {
    if (tokens) {
      fetchMe()
    }
  }, [tokens])
  // TODO : flat token
  useEffect(() => {
    const syncLocalStorage = async () => {
      const tokenString = await localStorage.getItem(TOKEN_STORAGE_KEY)
      const parsedToken = JSON.parse(tokenString)
      setTokens(parsedToken)
      setIsSync(true)
    }
    syncLocalStorage()
  }, [])

  return (
    <AuthContext.Provider value={providerValue}>
      {isSync ? children : null}
    </AuthContext.Provider>
  )
}
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider')
  }
  return context
}
