import globalApi from '../../api'

// users reports
export const getContributorNumberApi = ({ filter }) =>
  globalApi.post('/Reporting/NumberofUsers', { ...filter, isSecured: true })

export const getContributorNumberByGenderApi = ({ filter }) =>
  globalApi.post('/Reporting/UsersByGender', { ...filter, isSecured: true })

export const getContributorActiveRateApi = ({ filter }) =>
  globalApi.post('/Reporting/ActiveUsersRate', { ...filter, isSecured: true })

export const getContributorNumberByProfessionApi = ({ filter }) =>
  globalApi.post('/Reporting/UsersByPorfession', { ...filter, isSecured: true })

export const getContributorNumberByAgeApi = ({ filter }) =>
  globalApi.post('/Reporting/UsersByAge', { ...filter, isSecured: true })

export const getContributorTopWilayasApi = ({ filter }) =>
  globalApi.post('/Reporting/UsersTopWilaya?top=48', {
    ...filter,
    isSecured: true,
  })

// user dictionnary
export const getProfessionsApi = () =>
  globalApi.get('/User/Professions', { isSecured: true })

export const getAgeRangesApi = () =>
  globalApi.get('/User/AgeRanges', { isSecured: true })

// complaint report
export const getComplaintNumberApi = ({ filter }) =>
  globalApi.post('/Reporting/ComplaintsTotal', { ...filter, isSecured: true })

export const getComplaintNumberByNature = ({ filter }) =>
  globalApi.post('/Reporting/ComplaintsPerNature', {
    ...filter,
    isSecured: true,
  })
export const getComplaintNumberByTrashType = ({ filter }) =>
  globalApi.post('/Reporting/ComplaintsPerTrashType', {
    ...filter,
    isSecured: true,
  })
export const getComplaintNumberByStatus = ({ filter }) =>
  globalApi.post('/Reporting/ComplaintsPerStatus', {
    ...filter,
    isSecured: true,
  })

export const getComplaintTopWilayasApi = ({ filter }) =>
  globalApi.post('/Reporting/ComplaintsPerWilaya', {
    ...filter,
    isSecured: true,
  })

// complaint dictionnary

export const getTrashTypesApi = () =>
  globalApi.get('/Complaint/TrashTypes', { isSecured: true })

export const getNaturesApi = () =>
  globalApi.get('/Complaint/Natures', { isSecured: true })

export const changeComplaintStatusApi = ({ id: complaintId, statusType }) =>
  globalApi.put('/Complaint/StatusChange', {
    complaintId,
    statusType,
    isSecured: true,
  })

// get CSVs
export const getContributorCSVApi = ({ filter }) =>
  globalApi.post('/Reporting/GetUsersCSV', {
    ...filter,
    isSecured: true,
  })
export const getComplaintCSVApi = ({ filter }) =>
  globalApi.post('/Reporting/GetComplaintsCSV', {
    ...filter,
    isSecured: true,
  })
