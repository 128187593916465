import React, { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAlert } from '../../alert/containers/AlertProvider'
import {
  getContributorListApi,
  getProfessionsApi,
  getAgeRangesApi,
} from '../api'

export const ContributorsContext = createContext()

export const initialFilter = {
  orderBy: 0,
  genderIds: [],
  professionIds: [],
  wilayas: [],
  communes: [],
  ageRanges: [],
  phoneNumber: '',
}
const initialPagination = { currentPage: 0, take: 10, skip: 0, total: 0 }

export const ContributorsProvider = ({ children }) => {
  const { showAlert } = useAlert()
  const [isLoading, setIsLoading] = useState(false)

  const [professions, setProfessions] = useState([])
  const [ageRanges, setAgeRanges] = useState([])

  const [contributorList, setContributorList] = useState([])
  const [pagination, setPagination] = useState(initialPagination)

  const [filter, setFilter] = useState(initialFilter)
  // Todo: set skip to 0 after each setFilter
  const handlePagination = ({
    take = pagination.take,
    skip = pagination.skip,
    currentPage = pagination.currentPage,
  }) => {
    setPagination({ ...pagination, take, skip, currentPage })
  }

  const handleFilter = (newFilter) => {
    handlePagination({ currentPage: 0, skip: 0 })
    setFilter(newFilter)
  }

  const getProfessions = async () => {
    try {
      setIsLoading(true)
      const { data } = await getProfessionsApi()
      setProfessions(data)
    } catch {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }

  const getAgeRanges = async () => {
    try {
      setIsLoading(true)
      const { data } = await getAgeRangesApi()
      setAgeRanges(data)
    } catch {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }

  const getContributorsList = async () => {
    try {
      const {
        professionIds,
        genderIds,
        wilayas,
        communes,
        ageRanges: filterAgeRange,
        phoneNumber,
        orderBy,
      } = filter
      const { take, skip } = pagination

      setIsLoading(true)

      const requestFilter = {
        professions: professionIds,
        genders: genderIds,
        communeIds: communes.map(({ id }) => id),
        wilayaIds: communes.length === 0 ? wilayas.map(({ id }) => id) : [],
        orderBy,
        ageRanges: filterAgeRange,
        ...(phoneNumber.length > 3 && { phoneNumber }),
        take,
        skip,
      }

      const { data, headers } = await getContributorListApi({
        filter: requestFilter,
      })

      setContributorList(data)

      setPagination({ ...pagination, total: headers['x-total-count'] })
    } catch (e) {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }

  const init = () => {
    setFilter({ ...initialFilter })
    setPagination({ ...initialPagination })
  }

  const providerValue = {
    init,
    contributorList,
    professions,
    ageRanges,
    genders: [
      { id: 1, label: 'homme' },
      { id: 2, label: 'femme' },
      { id: -1, label: 'N/A' },
    ],
    filter,
    pagination,
    isLoading,
    handleFilter,
    handlePagination,
    getContributorsList,
  }

  useEffect(() => {
    if (professions.length > 0) {
      getContributorsList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, pagination.take, pagination.skip, professions, ageRanges])

  useEffect(() => {
    getProfessions()
    getAgeRanges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContributorsContext.Provider value={providerValue}>
      {children}
    </ContributorsContext.Provider>
  )
}
ContributorsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useContributors = () => {
  const context = useContext(ContributorsContext)
  if (context === undefined) {
    throw new Error(
      'useContributors must be used within a ContributorsProvider',
    )
  }
  return context
}
