import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { renderRoutes } from 'react-router-config'
import {
  Drawer,
  List,
  Divider,
  IconButton,
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ListItems from './ListItems'
import LogoIcon from '../LogoIcon'
import { useAuth } from '../../modules/security/containers/AuthProvider'

const drawerWidth = 210

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
  },
  displayHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerRoot: {
    position: 'fixed',
    height: '100vh',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: theme.spacing(10),
  },
  container: {
    padding: '0px',
    paddingRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  centerHorizontal: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  logoAndChevronContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  horizontalSpacer: {
    marginLeft: `${drawerWidth + theme.spacing(2)}px`,
  },
  listContainer: {
    height: '100%',
  },
  blockedScreen: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default function Layout({ route }) {
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  const { me, logout } = useAuth()

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  return me === null || me?.roles.length > 0 ? (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          root: classes.drawerRoot,
        }}
        open={open}
      >
        <div className={classes.logoAndChevronContainer}>
          <LogoIcon
            className={clsx(!open && classes.displayHidden)}
            height="45px"
          />
          <div
            className={clsx(
              classes.toolbarIcon,
              !open && classes.displayHidden,
            )}
          >
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
        </div>
        <div
          className={clsx(
            classes.toolbarIcon,
            classes.centerHorizontal,
            open && classes.displayHidden,
          )}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <Divider />
        <List className={classes.listContainer}>
          <ListItems />
        </List>
      </Drawer>
      <main className={clsx(classes.content, open && classes.horizontalSpacer)}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid item xs={12}>
            <div>{renderRoutes(route.routes)}</div>
          </Grid>
        </Container>
      </main>
    </div>
  ) : (
    <div className={classes.blockedScreen}>
      <Typography variant="body1" component="p">
        Votre compte a été bloqué veuillez contacter votre administrateur
      </Typography>
      <Button variant="contained" color="primary" onClick={logout}>
        Se déconnecter
      </Button>
    </div>
  )
}
Layout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  route: PropTypes.object.isRequired,
}
