import globalApi from '../../api'

export const getSupervisorsListApi = () =>
  globalApi.get('/Account/getSupervisorList', { isSecured: true })

export const createSupervisorApi = ({ email }) =>
  globalApi.post('/Account/createSupervisor', {
    email,
    isSecured: true,
  })
export const deleteSupervisorApi = ({ email }) =>
  globalApi.post('/Account/deleteSupervisor', {
    email,
    isSecured: true,
  })

export const banSupervisorApi = ({ email }) =>
  globalApi.post('/Account/banSupervisor', {
    email,
    isSecured: true,
  })

export const unbanSupervisorApi = ({ email }) =>
  globalApi.post('/Account/unbanSupervisor', {
    email,
    isSecured: true,
  })
