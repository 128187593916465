import React, { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useAlert } from '../../alert/containers/AlertProvider'
import {
  getComplaintListApi,
  getTrashTypesApi,
  getNaturesApi,
  changeComplaintStatusApi,
  resetComplaintStatusApi,
} from '../api'
import 'moment/locale/fr'

moment.locale('fr')

export const ComplaintsContext = createContext()

export const initialFilter = {
  orderBy: 0,
  natureIds: [],
  trashTypeIds: [],
  date: {
    startDate: null,
    endDate: null,
  },
  statuses: [],
  wilayas: [],
  communes: [],
  startTime: '00:00',
  endTime: '23:59',
  ownerPhoneNumber: '',
}

const initialPagination = { currentPage: 0, take: 10, skip: 0, total: 0 }

export const ComplaintsProvider = ({ children }) => {
  const { showAlert } = useAlert()
  const [isLoading, setIsLoading] = useState(false)

  const [complaintList, setComplaintList] = useState([])
  const [trashTypes, setTrashTypes] = useState([])
  const [natures, setNatures] = useState([])
  const [pagination, setPagination] = useState(initialPagination)

  const [filter, setFilter] = useState(initialFilter)

  const handlePagination = ({
    take = pagination.take,
    skip = pagination.skip,
    currentPage = pagination.currentPage,
  }) => {
    setPagination({ ...pagination, take, skip, currentPage })
  }

  const handleFilter = (newFilter) => {
    handlePagination({ currentPage: 0, skip: 0 })
    setFilter(newFilter)
  }

  const getTrashTypes = async () => {
    try {
      setIsLoading(true)
      const { data } = await getTrashTypesApi()
      setTrashTypes(data)
    } catch {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }

  const getNatures = async () => {
    try {
      setIsLoading(true)
      const { data } = await getNaturesApi()
      setNatures(data)
    } catch {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }

  const getComplaintsList = async () => {
    try {
      const {
        natureIds,
        trashTypeIds,
        wilayas,
        communes,
        date,
        ownerPhoneNumber,
        statuses,
        orderBy,
        startTime,
        endTime,
      } = filter
      const { take, skip } = pagination
      setIsLoading(true)

      const requestFilter = {
        natureIds,
        trashTypeIds,
        communeIds: communes.map(({ id }) => id),
        wilayaIds: communes.length === 0 ? wilayas.map(({ id }) => id) : [],
        orderBy,
        statuses,
        ...(date.startDate !== null && {
          startDate: moment(
            `${date.startDate.format('YYYY-MM-DD')} ${startTime}`,
          ).format(),
        }),
        // add 59 second to end time
        ...(date.endDate !== null && {
          endDate: moment(
            `${date.endDate.format('YYYY-MM-DD')} ${endTime}:59`,
          ).format(),
        }),
        ...(ownerPhoneNumber.length > 3 && { ownerPhoneNumber }),
        take,
        skip,
      }
      const { data, headers } = await getComplaintListApi({
        filter: requestFilter,
      })

      setComplaintList(data)

      setPagination({ ...pagination, total: headers['x-total-count'] })
    } catch (e) {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }

  const changeComplaintStatus = async ({ complaintsIds, statusType }) => {
    const newStatusTypes = await Promise.all(
      complaintsIds.map((id) =>
        changeComplaintStatusApi({
          id,
          statusType,
        }),
      ),
    )

    setComplaintList(
      complaintList.map((complaint) => {
        if (complaintsIds.indexOf(complaint.id) !== -1) {
          // use newStatusTypes[0].data because all statuses are the same
          return { ...complaint, status: newStatusTypes[0].data }
        }
        return complaint
      }),
    )
  }

  const resetComplaintStatus = async ({ id }) => {
    try {
      resetComplaintStatusApi({ id })
      setComplaintList(
        complaintList.map((complaint) => {
          if (complaint.id === id) {
            return { ...complaint, status: 1 }
          }
          return complaint
        }),
      )
    } catch {
      showAlert({ message: 'une erreur est survenue' })
    }
  }

  const init = () => {
    setFilter({ ...initialFilter })
    setPagination({ ...initialPagination })
  }

  const providerValue = {
    init,
    complaintList,
    trashTypes,
    natures,
    filter,
    statuses: [
      { id: 1, label: 'Nouvelle' },
      { id: 2, label: 'Envoyée' },
      { id: 3, label: 'Annulée' },
    ],
    pagination,
    isLoading,
    handleFilter,
    handlePagination,
    getComplaintsList,
    changeComplaintStatus,
    resetComplaintStatus,
  }

  useEffect(() => {
    if (natures.length > 0 && trashTypes.length > 0) {
      getComplaintsList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, pagination.take, pagination.skip, natures, trashTypes])

  useEffect(() => {
    getNatures()
    getTrashTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ComplaintsContext.Provider value={providerValue}>
      {children}
    </ComplaintsContext.Provider>
  )
}
ComplaintsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useComplaints = () => {
  const context = useContext(ComplaintsContext)
  if (context === undefined) {
    throw new Error('useComplaints must be used within a ComplaintsProvider')
  }
  return context
}
