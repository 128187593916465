import React from 'react'
import { useLocation } from 'react-router-dom'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import WarningIcon from '@material-ui/icons/Warning'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import KeyIcon from '@material-ui/icons/VpnKey'
import PersonIcon from '@material-ui/icons/Person'
import { useAuth } from '../../modules/security/containers/AuthProvider'
import Link from '../Link'
import uuid from '../../utils/random'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  selectedListItem: {
    backgroundColor: `${theme.palette.primary.main}!important`,
    color: `${theme.palette.primary.contrastText}!important`,
    // the fill is added to be inherited by icons on list items (must be same as color)
    fill: `${theme.palette.primary.contrastText}!important`,
  },
}))
const ListItems = () => {
  const classes = useStyles()

  const { hasRoles, logout } = useAuth()
  const { pathname } = useLocation()

  return (
    <div className={classes.root}>
      <div>
        <ListItem
          selected={pathname === '/app/dashboard'}
          button
          classes={{ selected: classes.selectedListItem }}
          component={Link}
          to={{ pathname: '/app/dashboard', state: { refreshId: uuid() } }}
          underline="none"
        >
          <ListItemIcon>
            <DashboardIcon style={{ fill: 'inherit' }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem
          selected={pathname === '/app/complaints'}
          button
          classes={{ selected: classes.selectedListItem }}
          component={Link}
          to={{ pathname: '/app/complaints', state: { refreshId: uuid() } }}
          underline="none"
        >
          <ListItemIcon>
            <WarningIcon style={{ fill: 'inherit' }} />
          </ListItemIcon>
          <ListItemText primary="Réclamations" />
        </ListItem>
        <ListItem
          selected={pathname === '/app/contributors'}
          button
          classes={{ selected: classes.selectedListItem }}
          component={Link}
          to={{ pathname: '/app/contributors', state: { refreshId: uuid() } }}
          underline="none"
        >
          <ListItemIcon>
            <PersonIcon style={{ fill: 'inherit' }} />
          </ListItemIcon>
          <ListItemText primary="Utilisateurs" />
        </ListItem>

        {hasRoles(['administrator']) && (
          <ListItem
            selected={pathname === '/app/supervisor'}
            button
            classes={{ selected: classes.selectedListItem }}
            component={Link}
            to={{ pathname: '/app/supervisor', state: { refreshId: uuid() } }}
            underline="none"
          >
            <ListItemIcon>
              <SupervisorAccountIcon style={{ fill: 'inherit' }} />
            </ListItemIcon>
            <ListItemText primary="Administration" />
          </ListItem>
        )}
      </div>
      {/* profil related links on the bottom of the drawer */}
      <div>
        <ListItem
          selected={pathname === '/app/change-password'}
          button
          classes={{ selected: classes.selectedListItem }}
          component={Link}
          to="/app/change-password"
          underline="none"
        >
          <ListItemIcon>
            <KeyIcon style={{ fill: 'inherit' }} />
          </ListItemIcon>
          <ListItemText primary="Mot de passe" />
        </ListItem>

        <ListItem button onClick={logout}>
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText primary="Déconnecter" />
        </ListItem>
      </div>
    </div>
  )
}
export default ListItems
