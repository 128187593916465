import React, { createContext, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

export const AlertContext = createContext()

const initialState = {
  message: '',
  variant: 'error',
  isVisible: false,
}

export const AlertProvider = (props) => {
  const { children } = props

  const [alertState, setAlertState] = useState(initialState)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setAlertState(initialState)
  }

  const showAlert = ({ message, variant = 'error' }) => {
    setAlertState({ message, variant, isVisible: true })
  }
  const { message, variant, isVisible } = alertState

  return (
    <AlertContext.Provider
      value={{
        showAlert,
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isVisible}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={variant}
        >
          {message}
        </MuiAlert>
      </Snackbar>
      {children}
    </AlertContext.Provider>
  )
}

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useAlert = () => {
  const context = useContext(AlertContext)
  if (context === undefined) {
    throw new Error('useAlertState must be used within a AlertProvider')
  }
  return context
}
