import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  noneUnderline: { textDecoration: 'none' },
  alwaysUnderline: { textDecoration: 'underline' },
  hoverUnderline: {
    textDecoration: 'none',
    '&:hover': { textDecoration: 'underline' },
  },
  primary: { color: theme.palette.primary.main },
  inherit: { color: 'inherit' },
  error: { color: theme.palette.error.main },
  secondary: { color: theme.palette.secondary.main },
  textPrimary: { color: theme.palette.text.primary },
  textSecondary: { color: theme.palette.text.secondary },
}))

function Link(props) {
  const { children, color, className, underline } = props
  const classes = useStyles()

  return (
    <RouterLink
      {...props}
      className={clsx(
        classes[`${underline}Underline`],
        classes[color],
        className,
      )}
    >
      {children}
    </RouterLink>
  )
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    'error',
    'inherit',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary',
  ]),
  className: PropTypes.string,
  underline: PropTypes.oneOf(['none', 'hover', 'always']),
}

Link.defaultProps = {
  color: 'textPrimary',
  className: '',
  underline: 'always',
}
export default Link
