import globalApi from '../../api'

export const getComplaintListApi = ({ filter }) =>
  globalApi.post('/Complaint/GetComplaintList', { ...filter, isSecured: true })

export const getTrashTypesApi = () =>
  globalApi.get('/Complaint/TrashTypes', { isSecured: true })

export const getNaturesApi = () =>
  globalApi.get('/Complaint/Natures', { isSecured: true })
export const getStatusesApi = () =>
  globalApi.get('/Complaint/Statuses', { isSecured: true })

export const changeComplaintStatusApi = ({ id: complaintId, statusType }) =>
  globalApi.put('/Complaint/StatusChange', {
    complaintId,
    statusType,
    isSecured: true,
  })
export const resetComplaintStatusApi = ({ id }) =>
  globalApi.put(`/Complaint/StatusReset?complaintId=${id}`, {
    isSecured: true,
  })
