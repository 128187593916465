import React from 'react'
import 'react-dates/initialize' // initialise react dates to ensure using stylesheet
import 'react-dates/lib/css/_datepicker.css' // import datepicker styles
import './app.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import routes from './pages'
import { AuthProvider } from './modules/security/containers/AuthProvider'
import { AlertProvider } from './modules/alert/containers/AlertProvider'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4C9666',
    },
  },
})

function App() {
  return (
    <Router>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <AuthProvider>{renderRoutes(routes)}</AuthProvider>
        </AlertProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
