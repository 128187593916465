import loadable from '@loadable/component'
import React from 'react'
import { matchRoutes } from 'react-router-config'
import LoadingPage from './Loading'

function findRouteByPathname({ routes = [], pathname = '' }) {
  const foundRoute = matchRoutes(routes, pathname).find(
    (item) => item.route.path === pathname,
  )

  if (foundRoute) {
    return foundRoute.route
  }

  return {}
}

export function getParentPathOfPathname(pathname) {
  if (!pathname) {
    return pathname
  }

  const path =
    pathname.substring(pathname.length - 1) === '/'
      ? pathname.substring(0, pathname.length - 1)
      : pathname

  return path.split('/').slice(0, -1).join('/')
}

function processRoutes({ routes = [], path = '', notFoundComponent }) {
  const formatedRoutes = routes.map((route) => {
    const item = { ...route }

    // formatPath
    if (item.path) {
      item.path = path + route.path
    }

    item.exact = Boolean((!item.routes || !item.routes.length) && item.path)

    // item.exact = Boolean(item.routes && item.routes.length)
    if (item.routes && item.routes.length) {
      item.routes = processRoutes({
        routes: item.routes,
        path: item.path,
        notFoundComponent,
      })

      // add notFoundRoute
      if (notFoundComponent) {
        item.routes.push({
          path: '*',
          component: notFoundComponent,
        })
      }
    }

    if (item.load) {
      item.component = loadable(item.load, { fallback: <LoadingPage /> })
    }

    return item
  })

  return formatedRoutes
}

export { processRoutes as default, findRouteByPathname }
