import React from 'react'
import { renderRoutes } from 'react-router-config'
import { Redirect } from 'react-router-dom'
import { useAuth } from '../containers/AuthProvider'
import { findRouteByPathname } from '../../../pages/helper'

function Firewall(props) {
  const { isAuthenticated, hasRoles } = useAuth()
  const { route, location } = props
  const { pathname } = location

  const { roles = [] } = findRouteByPathname({ routes: route.routes, pathname })

  const isRouteAuthorised = hasRoles(roles)
  const isRootPath = pathname === '/'
  const isAppPath = /^\/app(\/.*)?$/.test(pathname)

  if (!isAuthenticated && (isRootPath || isAppPath)) {
    return <Redirect to={{ pathname: '/login' }} />
  }

  // redirect to app if is user is logged-in;
  // if the user is not authorised redirect to /app
  if (isAuthenticated && (isRootPath || !isAppPath || !isRouteAuthorised)) {
    return <Redirect to={{ pathname: '/app' }} />
  }

  return renderRoutes(route.routes)
}

export default Firewall
