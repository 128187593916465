/* eslint-disable camelcase */
import wilayasData from './wilayasAndCommunesdata/wilayas.json'
import communesData from './wilayasAndCommunesdata/communes.json'

export const getWilayas = () =>
  wilayasData.map(({ name, id }) => ({ label: name, id }))

export const getCommunesOfWilayas = (selectedWilayas) => {
  return communesData
    .filter((commune) =>
      selectedWilayas.some(
        (selectedWilaya) => selectedWilaya.id === commune.idWilayaParent,
      ),
    )
    .map(({ id, name }) => ({
      id,
      label: name,
    }))
}
export const getWilayaLabel = (wilayaId) =>
  wilayasData.find(({ id }) => id === wilayaId)?.name || 'N/A'

export const getCommuneLabel = (communeId) =>
  communesData.find(({ id }) => id === communeId)?.name || 'N/A'

export const getLocalisation = ({ communeId, wilayaId }) =>
  `${getWilayaLabel(wilayaId)} > ${getCommuneLabel(communeId)}`.toLowerCase()

export const getLocalisationAr = ({ communeId, wilayaId }) =>
  `${wilayasData.find(({ id }) => id === wilayaId)?.name_ar || 'N/A'} < ${
    communesData.find(({ id }) => id === communeId)?.name_ar || 'N/A'
  }`
