import React, { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAlert } from '../../alert/containers/AlertProvider'
import {
  getSupervisorsListApi,
  createSupervisorApi,
  deleteSupervisorApi,
  banSupervisorApi,
  unbanSupervisorApi,
} from '../api'

export const SupervisorContext = createContext()

export const SupervisorProvider = ({ children }) => {
  const { showAlert } = useAlert()
  const [isLoading, setIsLoading] = useState(false)

  const [supervisorList, setSupervisorList] = useState([])

  const getSupervisorsList = async () => {
    try {
      setIsLoading(true)

      const { data } = await getSupervisorsListApi()
      setSupervisorList(data)
    } catch (e) {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }
  const banSupervisor = async ({ email }) => {
    try {
      setIsLoading(true)

      await banSupervisorApi({ email })
      setSupervisorList(
        supervisorList.map((supervisor) => {
          if (supervisor.email === email) {
            return { ...supervisor, status: 2 }
          }
          return supervisor
        }),
      )
    } catch (e) {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }
  const unbanSupervisor = async ({ email }) => {
    try {
      setIsLoading(true)

      await unbanSupervisorApi({ email })
      setSupervisorList(
        supervisorList.map((supervisor) => {
          if (supervisor.email === email) {
            return { ...supervisor, status: 1 }
          }
          return supervisor
        }),
      )
    } catch (e) {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }

  const createSupervisor = async ({ email }) => {
    try {
      setIsLoading(true)

      await createSupervisorApi({ email })
      setSupervisorList([...supervisorList, { email, status: 1 }])
      showAlert({
        variant: 'success',
        message: 'Le superviseur a bien été ajouté',
      })
    } catch (e) {
      if (e?.response?.data?.errorCode === 'DuplicateUserName') {
        showAlert({ message: 'Cet email existe déjà' })
      } else {
        showAlert({ message: 'une erreur est survenue' })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const deleteSupervisor = async ({ email }) => {
    try {
      setIsLoading(true)

      await deleteSupervisorApi({ email })
      setSupervisorList(
        supervisorList.filter((supervisor) => supervisor.email !== email),
      )
      showAlert({
        variant: 'success',
        message: 'Le superviseur a bien été supprimé',
      })
    } catch (e) {
      showAlert({ message: 'une erreur est survenue' })
    } finally {
      setIsLoading(false)
    }
  }

  const providerValue = {
    supervisorList,
    banSupervisor,
    unbanSupervisor,
    createSupervisor,
    deleteSupervisor,
    getSupervisorsList,
    isLoading,
  }

  useEffect(() => {
    getSupervisorsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SupervisorContext.Provider value={providerValue}>
      {children}
    </SupervisorContext.Provider>
  )
}
SupervisorProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useSupervisor = () => {
  const context = useContext(SupervisorContext)
  if (context === undefined) {
    throw new Error('useSupervisor must be used within a SupervisorProvider')
  }
  return context
}
